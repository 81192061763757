<template>
  <div class="signature" ref="signature">
    <!-- <white-board ref="whiteBoard"></white-board> -->
    <div class="signature_main">
      <div class="main_content">
        <div class="top_txt">
          <h1>请在框内签名</h1>
          <p>超出框的签名可能造成无效</p>
        </div>
        <white-board
          ref="whiteBoard"
          :isLandscape="isLandscape">
        </white-board>
        <div class="bottom_btns">
          <el-button
            size="small"
            @click="reset">
            重签
          </el-button>
          <el-button
            type="primary"
            size="small"
            :loading="btnLoading"
            @click="save">
            确定签名
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from '@/api'
import WhiteBoard from './white-board.vue'

export default {
  components: {
    WhiteBoard
  },
  data () {
    return {
      // 是否横屏
      isLandscape: false,
      // 按钮loading
      btnLoading: false
    }
  },
  computed: {
    // 需求id
    rId () {
      return this.$route.params.rId || ''
    },
    // 用户id
    uId () {
      return this.$route.params.uId || ''
    }
  },
  mounted () {
    window.addEventListener('resize', this.detectOrient)
    this.detectOrient()
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.detectOrient)
  },
  methods: {
    // 检测屏幕 强制横屏
    detectOrient () {
      const width = document.documentElement.clientWidth
      const height = document.documentElement.clientHeight
      const $wrapper = this.$refs.signature
      let style = ''
      // 页面宽比高大 横屏
      if (width >= height) {
        style += 'width:' + width + 'px;'
        style += 'height:' + height + 'px;'
        style += '-webkit-transform: rotate(0); transform: rotate(0);'
        style += '-webkit-transform-origin: 0 0;'
        style += 'transform-origin: 0 0;'
        this.isLandscape = true
      // 页面宽比高小 竖屏
      } else {
        style += 'width:' + height + 'px;'
        style += 'height:' + width + 'px;'
        style += '-webkit-transform: rotate(90deg); transform: rotate(90deg);'
        // 注意旋转中点的处理
        style += '-webkit-transform-origin: ' + width / 2 + 'px ' + width / 2 + 'px;'
        style += 'transform-origin: ' + width / 2 + 'px ' + width / 2 + 'px;'
        this.isLandscape = false
      }
      $wrapper.style.cssText = style

      this.$nextTick(() => {
        // 重绘canvas
        this.$refs.whiteBoard.initCanvas()
      })
    },

    // 重新签名
    reset () {
      this.$refs.whiteBoard.clearBoard()
    },
    // 确定签名 导出图片
    save () {
      const imgBase64 = this.$refs.whiteBoard.renderImage()
      this.uploadFileBase64(imgBase64)
    },
    // 获取需求详情
    uploadFileBase64 (imgBase64) {
      this.btnLoading = true
      api.uploadFileBase64({
        file: imgBase64,
        filename: '合同签名.png'
      }).then(res => {
        if (res.data.code === 0) {
          console.log('上传了合同签名', res.data.data)
          const mId = res.data.data.id
          // 提交签名
          this.submitSignature(mId)
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
        this.btnLoading = false
      })
    },
    // 提交签名
    submitSignature (mId) {
      this.btnLoading = true
      api.submitSignature(this.rId, {
        uid: this.uId,
        mediaId: mId
      }).then(res => {
        if (res.data.code === 0) {
          console.log('提交签名', res.data.data)
          this.$message.success('提交签名成功！')
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
        this.btnLoading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.signature {
  width: 100%;
  height: 100%;
  background: url('../../assets/images/signature/signature_bg.png') no-repeat center center/100% 100%;
  padding: 20px 60px;
  .signature_main {
    width: 100%;
    height: 100%;
    box-shadow: 0px 2px 8px rgba(9, 35, 72, 0.5);
    border-radius: 20px;
    padding: 25px 30px;
    background-color: #FFFFFF;
    border-radius: 10px;
    .main_content {
      position: relative;
      width: 100%;
      height: 100%;
      background-color: #FFFFFF;
      border: 1px solid #DCDFE6;
      padding: 13px 17px;
      border-radius: 10px;
      &::before {
        content: "";
        display: block;
        width: 91px;
        height: 94px;
        background: url('../../assets/images/signature/signature_bg_left.png') no-repeat center center/100% 100%;
        position: absolute;
        left: -7.4%;
        bottom: -16%;
      }
      &::after {
        content: "";
        display: block;
        width: 70px;
        height: 150px;
        background: url('../../assets/images/signature/signature_bg_right.png') no-repeat center center/100% 100%;
        position: absolute;
        right: -12%;
        bottom: -17%;
      }
      .top_txt {
        position: absolute;
        top: 13px;
        left: 17px;
        & > h1 {
          font-size: 14px;
          line-height: 20px;
          color: #303133;
          margin-bottom: 10px;
        }
        & > p {
          font-size: 11px;
          line-height: 15px;
          color: #C0C4CC;
        }
      }
      .bottom_btns {
        position: absolute;
        bottom: 10px;
        right: 10px;
      }
    }
  }
}
</style>
