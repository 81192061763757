<template>
  <div class="board" ref="board">
    <canvas
      ref="canvas"
      class="board_canvas"
      @mousedown="onMouseDown($event)"
      @mousemove="onMouseMove($event)"
      @mouseup="onMouseUp($event)"
      @touchstart="onMouseDown($event)"
      @touchmove="onMouseMove($event)"
      @touchend="onMouseUp($event)"
      @mouseout="onMouseOut($event)">
    </canvas>
  </div>
</template>

<script>
import Pencil from './pencil'

export default {
  props: {
    pencilSize: {
      type: Number,
      default: 8
    },
    pencilColor: {
      type: String,
      default: '#000'
    },
    // 是否横屏
    isLandscape: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      pencil: null
    }
  },
  methods: {
    initCanvas () {
      // tips:canvas设置宽高后会重绘
      this.$refs.canvas.width = this.$refs.board.clientWidth
      this.$refs.canvas.height = this.$refs.board.clientHeight

      const ctx = this.$refs.canvas.getContext('2d')
      if (!this.isLandscape) {
        // 移动原点
        ctx.translate(0, this.$refs.canvas.height)
        // 旋转90
        ctx.rotate(-0.5 * Math.PI)
      }
    },
    getCursorPosition (canvas, e) {
      const { top, left } = canvas.getBoundingClientRect()
      const clientX = e.clientX || e.changedTouches[0].clientX
      const clientY = e.clientY || e.changedTouches[0].clientY
      return [
        clientX - left,
        clientY - top
      ]
    },
    onMouseDown (e) {
      e.preventDefault()
      const ctx = this.$refs.canvas.getContext('2d')
      this.pencil = Pencil(ctx, 1)
      this.pencil.onMouseDown(...this.getCursorPosition(this.$refs.canvas, e), this.pencilColor, this.pencilSize)
    },
    onMouseMove (e) {
      if (!this.pencil) return

      e.preventDefault()
      this.pencil.onMouseMove(...this.getCursorPosition(this.$refs.canvas, e))
    },
    onMouseOut (e) {
      this.onMouseUp(e)
    },
    onMouseUp (e) {
      if (!this.pencil) return

      e.preventDefault()
      this.pencil.onMouseUp(...this.getCursorPosition(this.$refs.canvas, e))
    },
    clearBoard () {
      // const ctx = this.$refs.canvas.getContext('2d')
      // ctx.clearRect(0, 0, this.$refs.canvas.width, this.$refs.canvas.height)
      this.initCanvas()
    },
    renderImage () {
      const image = this.$refs.canvas.toDataURL('image/png')
      return image
    }
  }
}
</script>

<style lang="scss" scoped>
.board {
  width: 100%;
  height: 100%;
}
</style>
